import { inject, PLATFORM }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseFormViewModel }         from 'base-form-view-model';
import { RegulationsRepository }     from 'modules/management/regulations/services/repository';
import { RegulationFilesRepository } from 'modules/management/regulations/services/files-repository';
import { FormSchema }                from 'modules/management/regulations/form-schema';

@inject(AppContainer, RegulationsRepository, RegulationFilesRepository, FormSchema)
export class CreateRegulation extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.regulations.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    creating = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param formSchema
     */
    constructor(appContainer, repository, filesRepository, formSchema) {
        super(appContainer);

        this.repository      = repository;
        this.filesRepository = filesRepository;
        this.formSchema      = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/regulations/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.regulations.manage',
            'management.regulations.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => {
            this.schema              = this.formSchema.schema(this);
            this.globalButtonsSchema = this.formSchema.globalButtonsSchema(this);

            return true;
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.repository
            .create(this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status === true ? this.appContainer.router.navigateToRoute('management.regulations.edit', {id: response.model.id}) : false)
            .catch(console.log.bind(console));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
