import { inject }             from 'aurelia-framework';
import { AppContainer }       from 'resources/services/app-container';
import { BaseFormViewModel }  from 'base-form-view-model';
import { FormSchema }         from 'modules/management/settings/edit/form-schema';
import { SettingsRepository } from 'modules/management/settings/services/repository';

@inject(AppContainer, FormSchema, SettingsRepository)
export class EditSettings extends BaseFormViewModel {

    headerTitle = 'page.sidebar.settings';
    formId      = 'management.settings.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultFormView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.settings.manage',
            'management.settings.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.repository.update(this.model)
            .then(response => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.initialModel.assign(this.model);

                    this.fetchData();
                }
            })
            .catch(console.log.bind(console));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return this.repository.settings().then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

}
