import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseFormViewModel }          from 'base-form-view-model';
import { RegulationsRepository }      from 'modules/management/regulations/services/repository';
import { RegulationFilesRepository }  from 'modules/management/regulations/services/files-repository';
import { FormSchema }                 from 'modules/management/regulations/view/form-schema';

@inject(AppContainer, RegulationsRepository, RegulationFilesRepository, FormSchema)
export class ViewRegulation extends BaseFormViewModel {

    @bindable headerTitle     = 'form.title.view-record';
    @bindable editRecordRoute = 'management.regulations.edit';
    @bindable formId          = 'management.regulations.view-form';

    alert  = {};
    model  = {};
    schema = {};

    viewing = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param formSchema
     */
    constructor(appContainer, repository, filesRepository, formSchema) {
        super(appContainer);

        this.repository      = repository;
        this.filesRepository = filesRepository;
        this.formSchema      = formSchema;

        this.editRecordRoute = appContainer.authenticatedUser.can(['management.regulations.manage', 'management.regulations.edit']) ? this.editRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/regulations/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.regulations.manage',
            'management.regulations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema              = this.formSchema.schema(this);
            this.globalButtonsSchema = this.formSchema.globalButtonsSchema(this);

            return true;
        });
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({id}) {
        return Promise.all([
            this.repository.find(id).then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);
            }),
        ]);
    }

}
