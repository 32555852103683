import { inject }        from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { Setting }       from 'modules/management/models/setting';

@inject(DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param dialogService
     */
    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Setting}
     */
    model() {
        let model = new Setting();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.inspection_periodicity = {
            type:       'number',
            key:        'inspection_periodicity',
            label:      'form.field.inspection-periodicity(years)',
            size:       4,
            attributes: {
                min: 1,
            },
        };

        this.inspection_request_notification = {
            type:       'number',
            key:        'inspection_request_notification',
            label:      'form.field.inspection-request-notification(days)',
            size:       4,
            attributes: {
                min: 1,
            },
        };

        this.inspection_request_deadline = {
            type:       'number',
            key:        'inspection_request_deadline',
            label:      'form.field.inspection-request-deadline(days)',
            size:       4,
            attributes: {
                min: 1,
            },
        };

        this.inspection_request_deadline_after_notification = {
            type:       'number',
            key:        'inspection_request_deadline_after_notification',
            label:      'form.field.inspection-request-deadline-after-notification(days)',
            size:       4,
            attributes: {
                min: 1,
            },
        };

        this.inspection_execution_deadline = {
            type:       'number',
            key:        'inspection_execution_deadline',
            label:      'form.field.inspection-execution-deadline(days)',
            size:       4,
            attributes: {
                min: 1,
            },
        };

        this.scheduling_confirmation_deadline = {
            type:       'number',
            key:        'scheduling_confirmation_deadline',
            label:      'form.field.scheduling-confirmation-deadline(days)',
            size:       4,
            attributes: {
                min: 1,
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.inspection_periodicity, this.inspection_request_notification, this.inspection_request_deadline],
            [this.inspection_request_deadline_after_notification, this.inspection_execution_deadline, this.scheduling_confirmation_deadline],
            [this.buttons],
        ];
    }

}
