import { inject }                    from 'aurelia-framework';
import { Regulation }                from 'modules/management/models/regulation';
import { BooleanStatus }             from 'modules/administration/models/boolean-status';
import { BooleanStatusesRepository } from 'modules/administration/boolean-statuses/services/repository';

@inject(BooleanStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{status_id: number}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {Regulation}
     */
    model() {
        let model = new Regulation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param booleanStatusesRepository
     */
    constructor(booleanStatusesRepository) {
        this.booleanStatusesRepository = booleanStatusesRepository;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.name',
            size:  4,
        };

        this.description = {
            type:     'text',
            key:      'description',
            label:    'form.field.description',
            size:     8,
            required: false,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
        };

        return [
            [this.name, this.description],
            [this.status_id],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('management.regulations.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
